export const FETCH_LEAP_BETTING = 'FETCH_LEAP_BETTING';
export const LIST_LEAP_BETTING = 'LIST_LEAP_BETTING';
export const ERROR_LOADING = 'ERROR_LOADING';
export const MODIFIED_BETTING = 'MODIFIED_BETTING';
export const CREATE_BETTING = 'CREATE_BETTING';

export const CLEAR_ORDER = 'CLEAR_ORDER';
export const UPDATE_LIST_BETTING = 'UPDATE_LIST_BETTING';




export const FETCH_PAGING = 'FETCH_PAGING';
export const LIST_PAGING = 'LIST_PAGING';
export const CREATE_PAGING = 'CREATE_PAGING';

// filter
export const LIST_SHIFTS = 'LIST_SHIFTS';
export const LIST_PAGES = 'LIST_PAGES';
export const LIST_MEMBER = 'LIST_MEMBER';
export const LIST_LOTTERY = 'LIST_LOTTERY';
export const CLEAR = 'CLEAR';
export const LIST_LIMIT_NUMBERS = 'LIST_LIMIT_NUMBERS';

