import {FETCH_RESULTS, LIST_RESULTS} from "./types";


const init = {
    isLoading: false,
    filter: {},
    list: []
};


export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_RESULTS:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_RESULTS:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
            };
        default:
            return state;
    }
}
