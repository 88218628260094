import Cambodia from "../../assets/images/flags/cambodia.svg"
import UnitedState from "../../assets/images/flags/united-state.svg"
import Thailand from "../../assets/images/flags/thailand.svg"

export const getLocale = lang => {
    let locale = locales[1];
    locales.forEach(item => {
        if (item.lang === lang) {
            locale = item;
            return false;
        }
    });
    return locale;
};

export const locales = [
    {
        name: "ភាសាខ្មែរ",
        flag: Cambodia,
        lang: "km",
        locale: "km"
    },
    {
        name: "English",
        flag: UnitedState,
        lang: "en",
        locale: "en-au"
    },
    {
        name: "ประเทศไทย",
        flag: Thailand,
        lang: "th",
        locale: "th"
    },
    // {
    //     name: "中文",
    //     flag: Chinese,
    //     lang: "zh",
    //     locale: "zh-cn"
    // },
    // {
    //     name: "Việt Nam",
    //     flag: Vietnam,
    //     lang: "vn",
    //     locale: "vi"
    // },
    // {
    //     name: "대한민국",
    //     flag: Korea,
    //     lang: "kr",
    //     locale: "ko-KR"
    // },
    // {
    //     name: "சிங்கப்பூர்",
    //     flag: Singapore,
    //     lang: "sg",
    //     locale: "ta"
    // }
];