import {
    CREATE_SENIOR,
    FETCH_SENIOR,
    FETCH_SENIORS,
    LIST_SENIORS,
    MODIFIED_SENIOR,
    SHOW_SENIOR
} from "./types";

const init = {
    isLoading: true,
    list: [],
    active: {
        senior: {},
        isLoading: true
    },
    filter: {},
    page: 1,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_SENIORS:
            return {
                ...state,
                isLoading: true,
                filter: payload
            };
        case LIST_SENIORS:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case FETCH_SENIOR:
            return {
                ...state,
                active: {
                    isLoading: true,
                    senior: {}
                }
            };
        case SHOW_SENIOR:
            return {
                ...state,
                active: {
                    isLoading: false,
                    senior: payload
                }
            };
        case CREATE_SENIOR:
        case MODIFIED_SENIOR:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}