import {combineReducers} from "redux";
import playShift from "./playShift";
import rebates from "./rebates";
import lotteryType from "./lotteryType";
import shift from "./shift";
import drawingShift from "./drawingShift";


const gameConfigReducer = combineReducers({
    playShift,
    rebates,
    lotteryType,
    shift,
    drawingShift
});
export default gameConfigReducer;