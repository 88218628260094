import { combineReducers } from "redux";
import senior from "./senior";
import master from "./master";
import agent from "./agent";
import member from "./member";
import sub_account from "./sub_account";
import transaction from "./transaction";
import listReferralReducer from "./referral";
import super_senior from "./super_senior";
import game_config from "./game_config";

const referralReducer = combineReducers({
    senior,
    master,
    agent,
    member,
    sub_account,
    super_senior,
    game_config,
    transaction,
    referral: listReferralReducer
});

export default referralReducer;
