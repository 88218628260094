import {
    CREATE_AGENT,
    FETCH_AGENTS,
    FETCH_AGENT,
    SHOW_AGENT,
    LIST_AGENTS,
    MODIFIED_AGENT
} from "./types";

const init = {
    isLoading: true,
    list: [],
    active: {
        agent: {},
        isLoading: true
    },
    filter: {},
    page: 1,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_AGENTS:
            return {
                ...state,
                isLoading: true,
                filter: payload
            };
        case LIST_AGENTS:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case FETCH_AGENT:
            return {
                ...state,
                active: {
                    isLoading: true,
                    agent: {}
                }
            };
        case SHOW_AGENT:
            return {
                ...state,
                active: {
                    isLoading: false,
                    agent: payload
                }
            };
        case CREATE_AGENT:
        case MODIFIED_AGENT:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}