import {LIST_LIMIT_NUMBERS} from "./types";

const init = {
    items: {},
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case LIST_LIMIT_NUMBERS:
            return {
                ...state,
                items: payload ?? {},
            };
        default:
            return state;
    }
}