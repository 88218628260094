import {FETCH_LOTTERY_TYPE, LIST_LOTTERY_TYPE} from "./types";

const init = {
    isLoading: false,
    list: [],
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_LOTTERY_TYPE:
            return {
                ...state,
                isLoading: true
            };
        case LIST_LOTTERY_TYPE:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
            };
        default:
            return state;
    }
}