export  const FETCH_PLAY_SHIFTS='FETCH_PLAY_SHIFTS';
export  const LIST_PLAY_SHIFTS='LIST_PLAY_SHIFTS';
export const MODIFIED_PLAY_SHIFT='MODIFIED_PLAY_SHIFT';
export const CREATE_PLAY_SHIFT='CREATE_PLAY_SHIFT';
export const CLEAR_PLAY_SHIFT='CLEAR_PLAY_SHIFT';


export  const FETCH_REBATES='FETCH_REBATES';
export  const LIST_REBATES='LIST_REBATES';
export const MODIFIED_REBATES='MODIFIED_REBATES';
export const CLEAR_REBATES='CLEAR_REBATES';

export const FETCH_LOTTERY_TYPE='FETCH_LOTTERY_TYPE';
export const LIST_LOTTERY_TYPE='LIST_LOTTERY_TYPE';

export  const FETCH_SHIFTS='FETCH_SHIFTS';
export  const LIST_SHIFTS='LIST_SHIFTS';

export  const FETCH_DRAWING_SHIFTS ='FETCH_DRAWING_SHIFTS';
export  const LIST_DRAWING_SHIFTS ='LIST_DRAWING_SHIFTS';
export const CLEAR_DRAWING_SHIFTS ='CLEAR_DRAWING_SHIFTS';
