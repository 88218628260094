import {FETCH_PAGING, LIST_PAGING, CREATE_PAGING} from "./types";

const init = {
    isLoading: false,
    filter: {},
    data: []

};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_PAGING:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case CREATE_PAGING:
            return {
                ...state,
                isLoading: false,
            };
        case LIST_PAGING:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
            };
        default:
            return state;
    }
}