import {combineReducers} from "redux";
import saleReport from "./saleReport";
import dailyReport from "./dailyReport";
import mixDailyReport from "./mixDailyReport";
import mixTotalReport from "./mixTotalReport";

const lotteryReportsReducer = combineReducers({
    saleReport,
    dailyReport,
    mixDailyReport,
    mixTotalReport
});
export default lotteryReportsReducer;