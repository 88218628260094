import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Router } from 'react-router-dom';
import store from './redux/';
import * as serviceWorker from './serviceWorker';
import Config from './configure';
import Spinner from './components/spinner/Spinner';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "./index.scss"
import "./assets/fonts/feather/css/feather.css";
import "flag-icon-css/sass/flag-icon.scss";
import moment from "moment";
import "moment/min/locales.min";
import history from "./utility/history";

// I18next
import i18n from "./utility/locales/i18n";
import { I18nextProvider } from "react-i18next";

window.moment = moment;

const App = lazy(() => import("./App/App"));

const root = document.getElementById("root");

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router history={history} basename={Config.basename}>
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
      </Router>
    </Provider>
  </I18nextProvider>,
  root
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
