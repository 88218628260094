import {
  CREATE_EMPLOYEE, FETCH_EMPLOYEE,
  FETCH_EMPLOYEES,
  LIST_EMPLOYEES,
  MODIFIED_EMPLOYEE, SHOW_EMPLOYEE
} from "./types";

const init = {
  isLoading: true,
  list: [],
  active: {
    employee: {},
    isLoading: true
  },
  page: 1,
  totals: 0,
  size: 10
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_EMPLOYEES:
      return {
        ...state,
        isLoading: true
      };
    case LIST_EMPLOYEES:
      return {
        ...state,
        ...payload,
        isLoading: false
      };
    case FETCH_EMPLOYEE:
      return {
        ...state,
        active: {
          employee: {},
          isLoading: true
        }
      };
    case SHOW_EMPLOYEE:
      return {
        ...state,
        active: {
          isLoading: false,
          employee: payload
        }
      }
    case CREATE_EMPLOYEE:
    case MODIFIED_EMPLOYEE:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}