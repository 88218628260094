import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form';
import config from './templateConfig/';
import Navigation from './navigation/';
import referralReducer from "./referral";
import authReducer from "./auth";
import administrationReducer from "./administration";
import gameConfigReducer from "./gameconfig";
import systemConfigReducer from "./systemconfig";
import leapSaleReducer from "./sales";
import lotteryReportsReducer from "./lotteryreport";
import orderBettingReducer from "./ticket";
import resultReducer from "./results";

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    referral: referralReducer,
    administration: administrationReducer,
    gameConfig: gameConfigReducer,
    systemConfig: systemConfigReducer,
    lotteryReports: lotteryReportsReducer,
    sale: leapSaleReducer,
    orderBetting: orderBettingReducer,
    result: resultReducer,
    config,
    Navigation,
});

export default rootReducer;