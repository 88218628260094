import {CLEAR_SALE_REPORTS, FETCH_SALE_REPORTS, LIST_SALE_REPORTS} from "./types";

const init = {
    isLoading: false,
    data: null,
    filter: {},
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_SALE_REPORTS:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_SALE_REPORTS:
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        case CLEAR_SALE_REPORTS:
            return {
                ...init
            };
        default:
            return state;
    }
}