import {
    CLEAR_ORDER,
    CREATE_BETTING,
    ERROR_LOADING,
    FETCH_LEAP_BETTING,
    LIST_LEAP_BETTING,
    MODIFIED_BETTING,
    UPDATE_LIST_BETTING
} from "./types";

const init = {
    isLoading: false,
    filter: {},
    data: {
        drawShifts: [],
        pageNumber: 1,
        lanes: [],
        summaryAmount: {
            totalKhr: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0},
            totalUsd: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0}
        },
        total1Digit: {
            totalKhr: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0},
            totalUsd: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0}
        },

        total2Digit: {
            totalKhr: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0},
            totalUsd: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0}
        },
        total3Digit: {
            totalKhr: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0},
            totalUsd: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0}
        },
        total4Digit: {
            totalKhr: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0},
            totalUsd: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0}
        },
        total5Digit: {
            totalKhr: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0},
            totalUsd: {betAmount: 0, waterAmount: 0, winAmount: 0, winLoseAmount: 0}
        },
    },
    filters: null
};

export default (state = init, {type, payload, filters}) => {
    switch (type) {
        case FETCH_LEAP_BETTING:
            return {
                ...state,
                filter: payload,
                filters: filters,
                isLoading: true
            };
        case MODIFIED_BETTING:
        case CREATE_BETTING:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_LEAP_BETTING:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case UPDATE_LIST_BETTING:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    lanes: payload,
                },
            };
        case ERROR_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        case CLEAR_ORDER:
            return init;
        default:
            return state;
    }
}