
const init = {
  list: [
    {
      code: "ACTIVATE",
      name: "ACTIVATE"
    },
    {
      code: "DEACTIVATE",
      name: "DEACTIVATE"
    },
    // {
    //   code: "SUSPEND",
    //   name: "SUSPEND"
    // }
  ]
};

export default (state = init, {type, payload}) => {
  return state;
}