import {DO_LOCKED, DO_LOGIN, DO_LOGOUT, DO_UNLOCKED, GET_BALANCE, GET_PROFILE, UPDATE_BALANCE} from "./types";

const initState = {};

export default (state = initState, {payload, type}) => {
    switch (type) {
        case DO_LOGIN:
        case DO_LOGOUT:
        case DO_LOCKED:
        case DO_UNLOCKED:
        case GET_PROFILE:
            return payload;
        case GET_BALANCE:
            return {
                ...state,
                balance: payload
            };
        case UPDATE_BALANCE:
            return {
                ...state,
                balance: {
                    ...state.balance,
                    balanceAmount: payload
                }
            };
        default:
            return state;
    }
}