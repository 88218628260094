import {CLEAR, LIST_LOTTERY, LIST_MEMBER, LIST_PAGES, LIST_SHIFTS} from "./types";

const init = {
    pages: [],
    shifts: [],
    members: [],
    lotteries: [],
};

export default (state = init, {type, payload}) => {
    switch (type) {

        case LIST_PAGES:
            return {
                ...state,
                pages: payload,
            };
        case LIST_MEMBER:
            return {
                ...state,
                members: payload,
            };
        case LIST_SHIFTS:
            return {
                ...state,
                shifts: payload,
            };
        case LIST_LOTTERY:
            return {
                ...state,
                lotteries: payload,
            };
        case CLEAR:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
}


