import {FETCH_LIST_REFERRAL, LIST_LIST_REFERRAL} from "./types";

const init = {
  isLoading: false,
  list: []
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_LIST_REFERRAL:
      return {
        ...state,
        ...init,
        isLoading: true
      };
    case LIST_LIST_REFERRAL:
      return {
        ...state,
        isLoading: false,
        list: payload.data,
        ...payload.paging
      };
    default:
      return state;
  }
}