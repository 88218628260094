import {
    CREATE_MEMBER,
    FETCH_MEMBER,
    FETCH_MEMBERS,
    LIST_MEMBERS,
    MODIFIED_MEMBER,
    SHOW_MEMBER
} from "./types";

const init = {
    isLoading: true,
    list: [],
    active: {
        member: {},
        isLoading: true
    },
    filter: {},
    page: 1,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_MEMBERS:
            return {
                ...state,
                isLoading: true,
                filter: payload
            };
        case LIST_MEMBERS:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case FETCH_MEMBER:
            return {
                ...state,
                active: {
                    isLoading: true,
                    member: {}
                }
            };
        case SHOW_MEMBER:
            return {
                ...state,
                active: {
                    isLoading: false,
                    member: payload
                }
            };
        case CREATE_MEMBER:
        case MODIFIED_MEMBER:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}