import {
    CLEAR_PLAY_SHIFT,
    CREATE_PLAY_SHIFT,
    FETCH_PLAY_SHIFTS,
    LIST_PLAY_SHIFTS,
    MODIFIED_PLAY_SHIFT
} from "./types";

const init = {
    isLoading: false,
    filter: {
    },
    list: [],
    page: 1,
    size: 10,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_PLAY_SHIFTS:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_PLAY_SHIFTS:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
            };
        case CREATE_PLAY_SHIFT:
            return {
                ...state,
                isLoading: true,
                list: [],
            };
        case CLEAR_PLAY_SHIFT:
            return {
                ...state,
                list: [],
            };
        case MODIFIED_PLAY_SHIFT:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}