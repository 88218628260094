export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";
export const LIST_EMPLOYEES = "LIST_EMPLOYEES";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const SHOW_EMPLOYEE = "SHOW_EMPLOYEE";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const MODIFIED_EMPLOYEE = "MODIFIED_EMPLOYEE";

export const FETCH_ROLES = "FETCH_ROLES";
export const LIST_ROLES = "LIST_ROLES";
export const FETCH_ROLE = "LIST_ROLE";
export const GET_ROLE = "GET_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";

export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const LIST_PERMISSIONS = "LIST_PERMISSIONS";
export const FILTER_PERMISSIONS = "FILTER_PERMISSIONS";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const LIST_SETTINGS = "LIST_SETTINGS";
export const MODIFY_SETTINGS = "MODIFY_SETTINGS";
