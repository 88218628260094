import {combineReducers} from "redux";
import sale from "./sale";
import paging from "./paging";
import filter from "./filter";
import limitNumber from "./limitNumber";


const leapSaleReducer = combineReducers({
    sale,
    paging,
    filter,
    limitNumber
});
export default leapSaleReducer;