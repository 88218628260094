export const FETCH_SUPER_SENIORS = "FETCH_SUPER_SENIORS";
export const LIST_SUPER_SENIORS = "LIST_SUPER_SENIORS";
export const FETCH_SUPER_SENIOR = "FETCH_SUPER_SENIOR";
export const SHOW_SUPER_SENIOR = "SHOW_SUPER_SENIOR";
export const CREATE_SUPER_SENIOR = "CREATE_SUPER_SENIOR";
export const MODIFIED_SUPER_SENIOR = "MODIFIED_SUPER_SENIOR";
export const REMOVE_SUPER_SENIOR = "REMOVE_SUPER_SENIOR";

export const FETCH_USERS = "FETCH_USERS";
export const LIST_USERS = "LIST_USERS";

export const FETCH_SENIORS = "FETCH_SENIORS";
export const LIST_SENIORS = "LIST_SENIORS";
export const FETCH_SENIOR = "FETCH_SENIOR";
export const SHOW_SENIOR = "SHOW_SENIOR";
export const CREATE_SENIOR = "CREATE_SENIOR";
export const MODIFIED_SENIOR = "MODIFIED_SENIOR";
export const REMOVE_SENIOR = "REMOVE_SENIOR";

export const FETCH_MASTERS = "FETCH_MASTERS";
export const FETCH_MASTER = "FETCH_MASTER";
export const SHOW_MASTER = "SHOW_MASTER";
export const LIST_MASTERS = "LIST_MASTERS";
export const CREATE_MASTER = "CREATE_MASTER";
export const MODIFIED_MASTER = "MODIFIED_MASTER";
export const REMOVE_MASTER = "REMOVE_MASTER";

export const FETCH_AGENTS = "FETCH_AGENTS";
export const FETCH_AGENT = "FETCH_AGENT";
export const SHOW_AGENT = "SHOW_AGENT";
export const LIST_AGENTS = "LIST_AGENTS";
export const CREATE_AGENT = "CREATE_AGENT";
export const MODIFIED_AGENT = "MODIFIED_AGENT";
export const REMOVE_AGENT = "REMOVE_AGENT";

export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const FETCH_MEMBER = "FETCH_MEMBER";
export const SHOW_MEMBER = "SHOW_MEMBER";
export const LIST_MEMBERS = "LIST_MEMBERS";
export const CREATE_MEMBER = "CREATE_MEMBER";
export const MODIFIED_MEMBER = "MODIFIED_MEMBER";
export const REMOVE_MEMBER = "REMOVE_MEMBER";

export const FETCH_SUB_ACCOUNTS = "FETCH_SUB_ACCOUNTS";
export const FETCH_SUB_ACCOUNT = "FETCH_SUB_ACCOUNT";
export const LIST_SUB_ACCOUNTS = "LIST_SUB_ACCOUNTS";
export const SHOW_SUB_ACCOUNT = "SHOW_SUB_ACCOUNT";
export const CREATE_SUB_ACCOUNT = "CREATE_SUB_ACCOUNT";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const LIST_TRANSACTIONS = "LIST_TRANSACTIONS";

export const FETCH_LIST_REFERRAL = "FETCH_LIST_REFERRAL";
export const LIST_LIST_REFERRAL = "LIST_LIST_REFERRAL";

export const FETCH_GAME_CONFIGS = "FETCH_GAME_CONFIGS";
export const LIST_GAME_CONFIGS = "LIST_GAME_CONFIGS";
export const MODIFY_GAME_CONFIGS = "MODIFY_GAME_CONFIGS";
export const LIMIT_GAME_CONFIGS = "LIMIT_GAME_CONFIGS";