import {FETCH_GAME_CONFIGS, LIMIT_GAME_CONFIGS, LIST_GAME_CONFIGS} from "./types";

const init = {
  isLoading: false,
  list: [],
  page: 1,
  size: 10,
  total: 0
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_GAME_CONFIGS:
      return {
        ...state,
        isLoading: true
      };
    case LIST_GAME_CONFIGS:
      return {
        ...state,
        list: payload.data,
        ...payload.paging
      };
    case LIMIT_GAME_CONFIGS:
      return {
        ...state,
        limit: payload.data,
        ...payload.paging
      };
    default:
      return state;
  }
}