import {
    CREATE_SUPER_SENIOR,
    FETCH_SUPER_SENIOR,
    FETCH_SUPER_SENIORS,
    LIST_SUPER_SENIORS,
    MODIFIED_SUPER_SENIOR,
    SHOW_SUPER_SENIOR
} from "./types";

const init = {
    isLoading: true,
    list: [],
    active: {
        super_senior: {},
        isLoading: true
    },
    filter: {},
    page: 1,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_SUPER_SENIORS:
            return {
                ...state,
                isLoading: true,
                filter: payload
            };
        case LIST_SUPER_SENIORS:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case FETCH_SUPER_SENIOR:
            return {
                ...state,
                active: {
                    isLoading: true,
                    super_senior: {}
                }
            };
        case SHOW_SUPER_SENIOR:
            return {
                ...state,
                active: {
                    isLoading: false,
                    super_senior: payload
                }
            };
        case CREATE_SUPER_SENIOR:
        case MODIFIED_SUPER_SENIOR:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}