// import external modules
import { combineReducers } from "redux"
// import internal(own) modules
import profile from "./profile"

const authReducer = combineReducers({
  profile
});

export default authReducer;
