import {FETCH_SUB_ACCOUNT, FETCH_SUB_ACCOUNTS, LIST_SUB_ACCOUNTS, SHOW_SUB_ACCOUNT} from "./types";

const init = {
  isLoading: true,
  list: [],
  active: {
    isLoading: true,
    subAccount: {}
  },
  filter: {},
  page: 1,
  size: 10,
  totals: 0
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_SUB_ACCOUNTS:
      return {
        ...state,
        isLoading: true,
        filter: payload
      };
    case LIST_SUB_ACCOUNTS:
      return {
        ...state,
        isLoading: false,
        ...payload
      };
    case FETCH_SUB_ACCOUNT:
      return {
        ...state,
        active: {
          isLoading: true,
          subAccount: {}
        }
      };
    case SHOW_SUB_ACCOUNT:
      return {
        ...state,
        active: {
          subAccount: payload,
          isLoading: false
        }
      };
    default:
      return state;
  }
}