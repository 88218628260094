// import external modules
import {combineReducers} from "redux"
// import internal(own) modules
import employee from "./employee";
import role from "./role";
import setting from "./setting";
import status from "./status";

const administrationReducer = combineReducers({
    employee,
    setting,
    role,
    status
});

export default administrationReducer;
