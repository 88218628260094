import i18n from "i18next";
import axios from "axios";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from 'i18next-intervalplural-postprocessor';

import en from "../../assets/locales/en";
import km from "../../assets/locales/km";
import zh from "../../assets/locales/zh";
import th from "../../assets/locales/th";
import {getLocale} from "./locales";

i18n
    .use(LanguageDetector)
    .use(intervalPlural)
    .init({
        resources: {
            en,
            km,
            zh,
            th,
        },

        compatibilityJSON: "v3",
        ns: ["messages", "buttons", "validations", "labels"],
        lng:localStorage.getItem("i18nextLng") || "km",
        defaultNS: "validations",
        // Array of supported languages
        supportedLngs: ["en","km", "zh", "th"],
        // Language to use if translations in user language are not available
        fallbackLng: "en",
        // Logs info level to console output. Helps finding issues with loading not working
        debug: process.env.NODE_ENV === "development",
        // Options for react
        react: {
            // To wait for loaded in every translated hoc
            // wait: true,
            // Set it to false if you do not want to use Suspense
            useSuspense: true,
        },
    });

i18n.on("languageChanged", (lng) => {
    // moment.locale(getLocale(lng).locale);
  axios.defaults.headers.common["X-Localization"] = getLocale(lng).lang;
});
//
// moment.locale(getLocale(localStorage.getItem("i18nextLng")).locale);

export default i18n;
