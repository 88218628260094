import {CLEAR_DRAWING_SHIFTS, FETCH_DRAWING_SHIFTS, LIST_DRAWING_SHIFTS} from "./types";

const init = {
    isLoading: false,
    filter: {},
    list: [],
    page: 1,
    size: 10,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_DRAWING_SHIFTS:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_DRAWING_SHIFTS:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
                ...payload.paging
            };
        case CLEAR_DRAWING_SHIFTS:
            return {
                ...state,
                list: [],
            };
        default:
            return state;
    }
}