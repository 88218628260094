import {FETCH_REBATES, LIST_REBATES, MODIFIED_REBATES,CLEAR_REBATES} from "../gameconfig/types";

const init = {
    isLoading: false,
    filter: {},
    list: [],
    page: 1,
    size: 10,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_REBATES:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_REBATES:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
                ...payload.paging
            };
        case CLEAR_REBATES:
            return {
                ...state,
                list: [],
            };
        case MODIFIED_REBATES:
            return {
                ...state,
                isLoading: true};
        default:
            return state;
    }
}