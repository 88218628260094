import {FETCH_SHIFTS, LIST_SHIFTS} from "./types";

const init = {
    isLoading: false,
    filter: {
    },
    list: [],
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_SHIFTS:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_SHIFTS:
            return {
                ...state,
                isLoading: false,
                list: payload,
            };
        default:
            return state;
    }
}