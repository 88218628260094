export const LIST_SALE_REPORTS = 'LIST_SALE_REPORTS';
export const FETCH_SALE_REPORTS = 'FETCH_SALE_REPORTS';
export const CLEAR_SALE_REPORTS = 'CLEAR_SALE_REPORTS';

export const LIST_DAILY_REPORTS = 'LIST_DAILY_REPORTS';
export const FETCH_DAILY_REPORTS = 'FETCH_DAILY_REPORTS';
export const CLEAR_DAILY_REPORT = 'CLEAR_DAILY_REPORT';

export const LIST_MIX_DAILY_REPORTS = 'LIST_MIX_DAILY_REPORTS';
export const FETCH_MIX_DAILY_REPORTS = 'FETCH_MIX_DAILY_REPORTS';
export const CLEAR_MIX_DAILY_REPORT = 'CLEAR_MIX_DAILY_REPORT';

export const LIST_MIX_TOTAL_REPORTS = 'LIST_MIX_TOTAL_REPORTS';
export const FETCH_MIX_TOTAL_REPORTS = 'FETCH_MIX_TOTAL_REPORTS';
export const CLEAR_MIX_TOTAL_REPORTS = 'CLEAR_MIX_TOTAL_REPORTS';