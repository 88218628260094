import {FETCH_TRANSACTIONS, LIST_TRANSACTIONS} from "./types";

const init = {
  isLoading: false,
  list: [],
  page: 1,
  size: 10,
  totals: 0
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        ...init,
        isLoading: true
      };
    case LIST_TRANSACTIONS:
      return {
        ...state,
        isLoading: false,
        list: payload.data,
        ...payload.paging
      };
    default:
      return state;
  }
}